import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Text } from "@atoms";
import { m, useScroll, useTransform } from "framer-motion";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import useLang from "@hooks/useLang";
import ImageModalContainer from "./ImageModalContainer";

const Circle = loadable(() => import("../molecules/Circle"));

const VennDiagram = ({ circles, heading, copy }) => {
  const [arrived, setArrived] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [, dispatch] = useAppState();
  const vennRef = useRef(null);
  const outroRef = useRef(null);
  const [leaving, setLeaving] = useState(false);

  const lang = useLang();

  const setModalContent = idx => {
    dispatch({
      type: "openModal",
      // eslint-disable-next-line react/no-unstable-nested-components
      content: () => (
        <ImageModalContainer modalImages={circles} initial={idx} />
      ),
    });
  };

  const { scrollYProgress } = useScroll({
    target: vennRef,
    offset: ["0 1", "0.75 0.75"],
  });

  const { scrollYProgress: outroProgress } = useScroll({
    target: outroRef,
    offset: ["0 1", "1 1"],
  });

  const circleScale = useTransform(
    scrollYProgress,
    // Map x from these values:
    [0, 1],
    // Into these values:
    [0.1, 1.25]
  );

  const offset = useTransform(
    outroProgress,
    // Map x from these values:
    [0, 1],
    // Into these values:
    ["0vh", "50vh"]
  );

  const circlesLeaveScale = useTransform(
    outroProgress,
    // Map x from these values:
    [0, 1],
    // Into these values:
    [1, 0]
  );

  useEffect(() => {
    const unsubscribe = outroProgress.onChange(v => {
      setLeaving(leave => {
        if (v >= 0.01 && !leave) {
          return true;
        }
        if (v < 0.01 && leave) {
          return false;
        }
        return leave;
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setArrived(arr => {
        if (v >= 0.6 && !arr) {
          return true;
        }
        if (v < 0.6 && arr) {
          return false;
        }
        return arr;
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="h-full" ref={vennRef}>
      <m.div
        style={{ scale: circlesLeaveScale, y: offset }}
        className={classNames(
          "sticky top-0 z-10 flex h-screen w-full flex-col items-center justify-center",
          {
            "z-30": leaving,
          }
        )}
      >
        {/* <div
          className={classNames(
            "mr-auto mb-20 -mt-32 transition duration-200",
            {
              "-translate-x-4 opacity-0":
                (!arrived && !leaving) || (arrived && leaving),
            }
          )}
        >
          <CopySection heading={heading} copy={copy} inverse />
        </div> */}
        <div className="flex -translate-y-1/4">
          {circles.map((a, i) => (
            <m.button
              key={a.uid}
              onMouseEnter={() => setHovered(i)}
              onMouseLeave={() =>
                setHovered(h => {
                  if (h === i) {
                    return false;
                  }
                  return h;
                })
              }
              onClick={() => setModalContent(i)}
              style={{
                scale: circleScale,
                x: i === 0 ? "50%" : `-${(i - 1) * 50}%`,
                y: `${(i % 2) * 50}%`,
                // bottom: rippleOffset,
              }}
              className={classNames(
                "group relative z-0 block flex h-[50vw] max-h-[15rem] w-[50vw] max-w-[15rem] hover:z-10",
                {
                  "pointer-events-none -translate-y-[4rem]": !arrived,
                }
              )}
            >
              <Text
                className={classNames(
                  "block  font-bold uppercase",
                  {
                    "text-lg sm:text-xl lg:text-3xl": lang === "fr",
                    "text-xl sm:text-2xl sm:text-3xl": lang === "en",
                  },
                  "absolute z-10 w-full whitespace-pre-wrap text-white transition duration-300",
                  {
                    // left
                    "top-0 left-0 right-0 translate-x-[15%] text-left sm:-translate-x-1/4 md:-translate-x-1/2 xl:-translate-x-3/4":
                      i === 0,
                    // middle
                    "bottom-0 left-0 right-0 -translate-y-full text-center sm:translate-y-1/2 xl:translate-y-3/4":
                      i === 1,
                    // right
                    "right-0 top-0 right-0 -translate-x-[15%] text-right sm:translate-x-1/4 md:translate-x-1/2 xl:translate-x-3/4":
                      i === 2,
                    "opacity-0": !arrived || leaving,
                  }
                )}
              >
                {a.title}
              </Text>
              <div
                className={classNames(
                  "absolute inset-0 stroke-white stroke-[3px] text-transparent opacity-50 group-hover:stroke-coral sm:opacity-100",
                  {
                    // "translate-x-1/2": i === 0,
                    // "": i === 1,
                    // "-translate-x-1/2": i === 2,
                  }
                )}
              >
                <Circle
                  scrollYProgress={scrollYProgress}
                  hovered={hovered === i}
                  i={i}
                  fade={hovered !== false && hovered !== i}
                />
              </div>
            </m.button>
          ))}
        </div>
      </m.div>
      <div
        ref={outroRef}
        className="absolute bottom-0 left-0 right-0 h-[30%]"
      />
    </div>
  );
};

VennDiagram.defaultProps = {};

export default VennDiagram;
